.aboutUs {
  width: 100%;
  margin: 0;
  padding: 0;
}

.logoAboutUs {
  position: fixed;
  width: 150px;
  margin-left: 105px;
  margin-top: 2vh;
}

.firtsAboutUs {
  display: flex;
}

.MP_firtsAboutUs {
  width: 45%;
  height: 90vh;
}

.Slide_firtsAboutUs {
  position: relative;
  width: 55%;
  float: right;
  margin-right: 2%;
  animation: showFromRight 1.5s linear 0s alternate;
}

#page-title-about-us {
  font-size: 70px;
  letter-spacing: -9px;
}

.page-text-about-us {
  margin-top: 10px;
  padding-bottom: 10px;
}

#title-non-bold-about-us {
  font-size: 60px;
  letter-spacing: -5px;
  font-weight: 500;
}

.page-right-first-row {
  display: flex;
  margin-top: 5vh;
  width: 100%;
}

.page-right-item {
  margin-left: 5%;
  margin-right: 5%;
  width: 40%;
  height: 200px;
}

@keyframes showFromRight {
  0% {
    right: -800px;
  }
  100% {
    right: 0%;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 650px) {
  .MP_firtsAboutUs {
    width: 100%;
    height: auto;
  }

  .logoAboutUs {
    position: absolute;
    height: 5vh;
    margin: 2vh 7% 0 0;
    right: 0;
  }

  .Slide_firtsAboutUs {
    display: none;
  }

  #title-non-bold-about-us {
    font-size: 33px;
    letter-spacing: -2px;
  }
  #page-title-about-us {
    font-size: 35px;
    letter-spacing: -3px;
  }
}
