.page {
  width: 100%;
  height: 90vh;
  display: flex;
}

.nothing-mobile {
  display: none;
}

.page-left {
  margin-top: 10vh;
  display: block;
  position: absolute;
  margin-left: 10%;
  width: 38%;
  z-index: 100;
}

.page-title {
  font-size: 100px;
  letter-spacing: -11px;
  line-height: 72%;
  font-weight: 800;
  padding-bottom: 18px;
}

.page-text {
  margin-top: 10px;
  padding-bottom: 20px;
}

#title-non-bold {
  font-size: 80px;
  letter-spacing: -5px;
  font-weight: 500;
}

.page-right {
  margin-top: 10vh;
  margin-left: 50%;
  width: 45%;
}

.page-right-mobile {
  display: none;
}

.page-right-mobile-partneri {
  display: none;
}

.page-img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

.page-slide {
  position: relative;
  width: 100%;
  margin-right: 2%;
  animation: showFromRight 1.5s linear 0s alternate;
}

.red-bold-text {
  font-size: 20px;
  color: #c30c0e;
  font-weight: 900;
}

.bold-word {
  font-size: 18px;
  font-weight: 900;
}

.red-italic-text {
  color: #c30c0e;
  font-weight: bold;
  font-style: italic;
}

#page-text-not-display {
  display: block;
}

.page-space {
  height: 50px;
}

@media only screen and (max-width: 650px) {
  .page {
    display: block;
    height: auto;
  }
  .page-left {
    margin-top: 3vh;
    margin-left: 3%;
    width: 94%;
    position: relative;
    z-index: 5;
  }

  .page-title {
    font-size: 45px;
    letter-spacing: -3px;
    font-weight: 900;
    padding-bottom: 10px;
    padding-top: 50px;
    text-align: right;
  }

  #title-non-bold {
    font-size: 35px;
    letter-spacing: -2px;
    font-weight: 600;
  }

  .page-text {
    position: relative;
    margin-top: 10px;
    padding-bottom: 15px;
  }

  #page-text-not-display {
    display: none;
  }

  .page-right {
    display: none;
  }

  .page-right-mobile {
    position: relative;
    display: block;
    margin-top: 0vh;
    width: 100%;
  }

  .page-right-mobile-partneri {
    position: relative;
    display: block;
    margin-top: 0vh;
    width: 100%;
  }

  .page-img {
    height: auto;
  }

  .nothing-mobile {
    height: 8vh;
  }

  .page-space {
    height: 0px;
  }
}
