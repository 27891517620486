.screen3 {
  display: flex;
}

.left-screen3 {
  display: block;
}

.right-screen3 {
  margin-top: 15vh;
}

#nove-tehnologije {
  padding-top: 20px;
  right: 0px;
}

.title-screen3 {
  position: absolute;
  margin-left: 10%;
  color: black;
  margin-top: 9vh;
  font-size: 80px;
  z-index: 200;
  line-height: 0.8;
  animation: show-title-screen3 2.5s linear 0s alternate;
}

@keyframes show-title-screen3 {
  0% {
    display: none;
    z-index: -10;
    margin-left: -1600px;
  }
  70% {
    display: none;
    z-index: -10;
    margin-left: -800px;
  }
  90% {
    display: block;
    z-index: 200;
    margin-left: 7%;
  }
  100% {
    display: block;
    z-index: 200;
    margin-left: 10%;
  }
}

.text-screen3 {
  position: absolute;
  width: 40%;
  text-align: justify;
  margin-top: 73vh;
  margin-left: 11%;
  margin-bottom: 60px;
}

.bold-text {
  font-size: 120px;
  font-weight: 900;
  letter-spacing: -10px;
}

.images-screen3 {
  width: 42%;
  margin-top: 20vh;
  margin-left: 11%;
  position: absolute;
  animation: show-img-screen3 1.5s linear 0s alternate;
}

.images-screen3 img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

@keyframes show-img-screen3 {
  0% {
    margin-left: -800px;
  }
  100% {
    margin-left: 11%;
  }
}

#image-0 {
  position: absolute;
  display: block;
}

#image-1 {
  position: absolute;
  display: none;
}

#image-2 {
  position: absolute;
  display: none;
}

#image-3 {
  position: absolute;
  display: none;
}

.arrow-screen3 {
  position: absolute;
  margin-top: 42vh;
  margin-left: 72%;
  z-index: 500;
}

.arrow-screen3 button {
  background: none;
  border: none;
  font-size: 40px;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.text-right-screen3 {
  position: absolute;
  margin-left: 72vw;
  margin-top: 15vh;
  font-size: 32px;
  color: #a4a4a4;
  font-weight: 600;
  text-align: left;
  line-height: 90%;
}

.text-right-screen3 a {
  color: #a4a4a4;
  text-decoration: none;
}

.caret-right {
  color: black;
}

#caret-right-najnovije {
  display: none;
}

.text-right-screen3 .najnovije:hover a {
  color: black;
  font-size: 37px;
}

.text-right-screen3 .najnovije:hover #caret-right-najnovije {
  display: inline;
  font-size: 37px;
}

#nove-tehnologije-title {
  letter-spacing: -8px;
}

.row-screen3 {
  margin-bottom: 50px;
  display: flex;
}

.img-right-screen3 {
  position: relative;
  margin-left: 58vw;
  display: block;
}

.img-right-screen3 img {
  width: 40%;
  position: relative;
  object-fit: cover;
  z-index: 10;
}

.text-span {
  width: 50%;
  margin-left: 15px;
  font-size: 14px;
  animation: show-text-span 2.5s linear 0s alternate;
}

@keyframes show-text-span {
  0% {
    z-index: -10;
    opacity: 0;
    margin-left: -50%;
  }
  70% {
    z-index: -10;
    opacity: 0;
    margin-left: -50%;
  }
  90% {
    display: block;
    z-index: 1;
    margin-left: -30%;
  }
  100% {
    display: block;
    z-index: 1;
    margin-left: 15px;
  }
}

#red-text {
  font-size: 18px;
  color: #c30c0e;
  font-weight: bold;
}

.caret-right-button-screen3 {
  font-size: 15px;
  padding-left: 10px;
}

.button-screen3 {
  margin-top: 2vh;
  position: absolute;
  float: right;
  right: 7%;
}

.button-tema {
  background-color: #c30c0e;
  color: white;
  font-size: 13px;
  padding: 10px 30px 10px 30px;
  border: #c30c0e;
  border-radius: 25px;
  justify-content: right;
  cursor: pointer;
}

@media only screen and (max-width: 1500px) {
  .title-screen3 {
    font-size: 55px;
  }
  .bold-text {
    font-size: 95px;
  }
  .button-screen3 {
    margin-top: 1vh;
  }
  .text-right-screen3 {
    font-size: 28px;
    margin-left: 71vw;
  }
  .text-right-screen3 .najnovije:hover a {
    font-size: 32px;
  }
}

@media only screen and (max-width: 1150px) {
  .title-screen3 {
    font-size: 45px;
  }
  .bold-text {
    font-size: 75px;
    letter-spacing: -8px;
  }
  .arrow-screen3 {
    margin-left: 68%;
  }
  .text-right-screen3 {
    font-size: 24px;
  }
  .text-right-screen3 .najnovije:hover a {
    font-size: 28px;
  }
  .text-screen3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 850px) {
  .title-screen3 {
    margin-left: 12%;
    font-size: 40px;
  }
  .images-screen3 {
    margin-left: 13%;
    width: 36%;
  }
  .text-screen3 {
    margin-left: 13%;
    width: 36%;
  }
  .right-screen3 {
    margin-top: 23vh;
  }

  .img-right-screen3 {
    margin-left: 52vw;
  }

  .text-span {
    font-size: 12px;
    animation: none;
  }
  #nove-tehnologije-title {
    letter-spacing: -3px;
  }
}

@media only screen and (max-width: 720px) {
  .title-screen3 {
    margin-left: 14%;
    font-size: 35px;
  }
  .images-screen3 {
    margin-left: 15%;
  }
  .text-screen3 {
    margin-left: 15%;
  }
}

@media only screen and (max-width: 650px) {
  .screen3 {
    display: block;
  }

  .right-screen3 {
    margin-top: 20px;
  }
  .title-screen3 {
    position: relative;
    margin-top: 40px;
    margin-left: 19%;

    font-size: 30px;
    animation: none;
  }
  .images-screen3 {
    margin-top: 20px;
    position: relative;
    width: 90%;
    margin-left: 5%;
    animation: none;
  }

  .row-screen3 {
    margin-bottom: 100px;
  }

  #image-0 {
    position: relative;
  }

  .text-screen3 {
    margin-top: 15px;
    position: relative;
    margin-left: 5%;
    width: 90%;
    padding-bottom: 30px;
  }

  .img-right-screen3 {
    margin-left: 5%;
  }

  .bold-text {
    font-size: 40px;
    letter-spacing: -3px;
  }
}
