.middlePage1 {
  display: flex;
}

#allInOne {
  margin-top: -9vh;
}

.middlePage1 .titleLeft {
  margin-left: 150px;
  animation: showFromLeft 1s linear 0s alternate;
}

.middlePage1 .titlesAboutUs {
  position: absolute;
  font-size: 12vh;
  text-align: left;
  letter-spacing: -6.8px;
  line-height: 90%;
  z-index: 100;
  margin-top: 15vh;
}

.middlePage1 .bold {
  font-weight: 900;
  font-size: 16vh;
  letter-spacing: -6.8px;
}

.middlePage1 .about-us-text {
  position: absolute;
  margin: 45vh 0 0 1%;
  width: 25vw;
  font-size: 2.1vh;
  color: black;
  z-index: 100;
  font-weight: 550;
}

.middlePage1 .about-us-text span {
  color: red;
}

.imgMiddlePage1 {
  display: none;
}

@keyframes showFromLeft {
  0% {
    margin-left: -400px;
  }
  100% {
    margin-left: 150px;
  }
}

@keyframes showFromRight {
  0% {
    right: -800px;
  }
  100% {
    right: 5%;
  }
}

@media (max-width: 1200px) {
  .middlePage1 .titlesAboutUs {
    font-size: 9vh;
  }

  .middlePage1 .bold {
    font-size: 12vh;
  }

  .middlePage1 .about-us-text {
    margin: 40vh 0 0 0;
  }
}

@media (max-width: 1100px) {
  .middlePage1 .about-us-text {
    margin: 37vh 0 0 0;
    width: 28vw;
  }
}

@media (max-width: 1000px) {
  .middlePage1 .titlesAboutUs {
    margin-top: 11vh;
  }

  .middlePage1 .about-us-text {
    margin: 32vh 0 0 0;
    width: 28vw;
  }
}

@media (max-width: 950px) {
  .middlePage1 .titlesAboutUs {
    margin-left: -42px;
  }

  .middlePage1 .about-us-text {
    margin: 32vh 0 0 -35px;
    width: 30vw;
    font-size: 1.8vh;
  }
}

@media (max-width: 650px) {
  .middlePage1 {
    display: none;
  }
}
