#title-non-bold-horeca {
  font-size: 65px;
  letter-spacing: -5px;
  font-weight: 500;
}

@media only screen and (max-width: 650px) {
  #title-non-bold-horeca {
    font-size: 35px;
  }
}
