.footer {
  width: 100%;
  background-color: #c30c0e;
  padding: 40px 0;
  position: relative;
  color: white;
}

.center {
  max-width: 1220px;
  margin: 0 0 0 80px;
  width: 100%;
}

.clear {
  clear: both;
}

.footer .copyright {
  text-align: center;
  font-size: 11px;
  margin: 50px 0 0 0;
}

@media only screen and (max-width: 650px) {
  .footer {
    display: none;
  }
}
