.header {
  position: fixed;
  display: block;
  width: 80px;
  margin-top: 8vh;
  height: 84vh;
  background-color: #c30c0e;
  z-index: 500;
}

.content {
  text-align: center;
}

.fa_bars input {
  display: none;
}

.fa_bars .fa_bars_icon {
  margin-top: 20px;
  color: white;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.fa_bars:hover .header_list ul {
  display: block;
}

.header_list ul {
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px 20px 30px 0px;
  margin: -72px 0 0 80px;
}

.header_list li {
  text-align: left;
  font-size: 40px;
  list-style: none;
  color: black;
  width: 300px;
  margin: 5px 0 5px 30px;
}

.fa_bars li a {
  text-decoration: none;
  color: black;
  padding: 3px;
}

.fa_bars li a:hover {
  font-weight: bolder;
}

.languages {
  margin-left: 22px;
  margin-top: 22px;
}

.languages button {
  display: block;
  background-color: #c30c0e;
  color: #d0d0d0;
  font-size: 24px;
  border: none;
  cursor: pointer;
  outline: none;
}

#en {
  color: #810708;
}

#sr {
  color: #d0d0d0;
}

.info {
  position: absolute;
  bottom: 0px;
  color: white;
  text-align: center;
  margin-left: 6px;
}

.info div {
  padding-bottom: 20px;
}

/* @media (max-height: 900px) {
  .info {
    margin-top: 30vh;
  }
} */

@media (max-height: 750px) {
  /* .info {
    margin-top: 20vh;
  } */
  .languages button {
    font-size: 18px;
  }
}

@media (max-height: 550px) {
  .info {
    margin-top: 2vh;
  }
  .info div {
    padding-bottom: 10px;
  }
  .info div a img {
    width: 20px;
  }
  .info p {
    font-size: 14px;
  }
  .languages {
    margin-top: 10px;
  }
  .fa_bars .fa_bars_icon {
    margin-top: 10px;
    width: 30px;
    height: 30px;
  }
}

@media (max-height: 340px) {
  .info {
    margin-top: 1vh;
  }
  .info div {
    padding-bottom: 7px;
  }
  .info div a img {
    width: 18px;
  }
  .info p {
    font-size: 12px;
  }
  .languages {
    margin-top: 6px;
  }
  .fa_bars .fa_bars_icon {
    margin-top: 8px;
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: 1500px) {
  .header_list li {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1150px) {
  .header_list li {
    font-size: 35px;
  }
}

@media only screen and (max-width: 900px) {
  .header_list li {
    font-size: 28px;
    width: 150px;
  }
  .header {
    width: 90px;
  }

  .header_list ul {
    margin-left: 90px;
  }

  .info {
    margin-top: 24vh;
  }
}

@media only screen and (max-height: 550px) and (max-width: 810px) {
  .header_list ul {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 750px) {
  .header_list li {
    font-size: 23px;
  }
}

@media only screen and (max-width: 650px) {
  /* .header_list li {
        font-size: 20px;
    }
    .header {
        width: 70px;
    }
    .header_list ul {
        margin-left: 70px;
    }
    .languages {
        margin-left: 18px;
    }
    .header_list ul {
        margin-top: -50px; 
    } */

  .header,
  .fa_bars {
    display: none;
  }
}

@media only screen and (max-height: 600px) and (min-width: 1150px) {
  .header_list li {
    font-size: 40px;
  }
}
