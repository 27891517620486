#page-right-blog {
  position: fixed;
}

#page-title-blog {
  font-size: 50px;
  letter-spacing: -5px;
  line-height: 100%;
  font-weight: 800;
}

#title-non-bold-blog {
  font-size: 40px;
  letter-spacing: -3px;
  font-weight: 500;
}
