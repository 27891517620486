.poliplan_in_numbers_img img {
  height: 99vh;
  width: 100%;
  z-index: -1;
}

.poliplan_in_numbers_mobile img {
  display: none;
}

.poliplan_in_numbers_logo {
  position: absolute;
  margin-top: -6vh;
  right: 3%;
}

.poliplan_in_numbers_logo img {
  height: 4vh;
}

.gradovi {
  position: absolute;
  border-radius: 50%;
  border: none;
  width: 1vw;
  height: 1.7vh;
  z-index: 10;
  background: transparent;
}

.subotica {
  margin-top: -84.2vh;
  margin-left: 51.93%;
}

.subotica_okvir {
  margin-top: -84vh;
  margin-left: 52.1%;
  width: 0.8vw;
  height: 1.5vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.subotica:hover .subotica_hover {
  font-size: 22px;
}

.novi_sad {
  margin-top: -73.5vh;
  margin-left: 51.3%;
}

.novi_sad_okvir {
  margin-top: -73.42vh;
  margin-left: 51.33%;
  width: 0.9vw;
  height: 1.6vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.beograd {
  margin-top: -71.3vh;
  margin-left: 53.6%;
  width: 3vw;
  height: 4.5vh;
}

.sabac {
  margin-top: -65.3vh;
  margin-left: 52.3%;
}

.sabac_okvir {
  margin-top: -65.1vh;
  margin-left: 52.45%;
  width: 0.7vw;
  height: 1.3vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.uzice {
  margin-top: -59.5vh;
  margin-left: 51.7%;
}

.uzice_okvir {
  margin-top: -59.2vh;
  margin-left: 51.85%;
  width: 0.7vw;
  height: 1.3vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.cacak {
  margin-top: -60.2vh;
  margin-left: 54.6%;
}

.cacak_okvir {
  margin-top: -60vh;
  margin-left: 54.8%;
  width: 0.7vw;
  height: 1.45vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.kragujevac {
  margin-top: -62.8vh;
  margin-left: 56.8%;
  width: 1.3vw;
  height: 2.2vh;
}

.kragujevac_okvir {
  margin-top: -62.7vh;
  margin-left: 56.94%;
  width: 1.15vw;
  height: 2.1vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.kraljevo {
  margin-top: -59vh;
  margin-left: 57.5%;
}

.kraljevo_okvir {
  margin-top: -58.8vh;
  margin-left: 57.66%;
  width: 0.8vw;
  height: 1.45vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.nis {
  margin-top: -48.5vh;
  margin-left: 62.5%;
  width: 1.3vw;
  height: 2.2vh;
}

.nis_okvir {
  margin-top: -48.27vh;
  margin-left: 62.62%;
  width: 1.1vw;
  height: 1.9vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.crna_gora {
  margin-top: -37vh;
  margin-left: 44.8%;
  width: 2.5vw;
  height: 4vh;
}

.crna_gora_okvir {
  margin-top: -35.85vh;
  margin-left: 45.65%;
  width: 0.9vw;
  height: 1.8vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.makedonija {
  margin-top: -25.1vh;
  margin-left: 62.1%;
  width: 2.5vw;
  height: 4vh;
}

.makedonija_okvir {
  margin-top: -24.25vh;
  margin-left: 62.8%;
  width: 1.25vw;
  height: 2.3vh;
  background-color: #c30c0e;
  border: 1px solid #c30c0e;
  box-shadow: 3px 0 25px 3px #c30c0e;
}

.hover {
  position: absolute;
  font-weight: 600;
  letter-spacing: 4px;
  font-size: 2vh;
}

.beograd_hover {
  margin-top: -73.5vh;
  margin-left: 21%;
  width: 32%;
}

.beograd_naslov {
  border-bottom: 2px solid #c30c0e;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.beograd_right_hover {
  margin-top: -71.1vh;
  margin-left: 57%;
  width: 27%;
}

.subotica_hover {
  margin-top: -84vh;
  margin-left: 54%;
  width: 27%;
}

.novi_sad_hover {
  margin-top: -74vh;
  margin-left: 23%;
  width: 27%;
}

.kragujevac_hover {
  margin-top: -64vh;
  margin-left: 58%;
  width: 35%;
}

.kraljevo_hover {
  margin-top: -58vh;
  margin-left: 59%;
  width: 35%;
}

.cacak_hover {
  margin-top: -61vh;
  margin-left: 25%;
  width: 30%;
}

.uzice_hover {
  margin-top: -60vh;
  margin-left: 25%;
  width: 27%;
}

.sabac_hover {
  margin-top: -66vh;
  margin-left: 25%;
  width: 27%;
}

.nis_hover {
  margin-top: -49vh;
  margin-left: 64%;
  width: 30%;
}

.crna_gora_hover {
  margin-top: -38vh;
  margin-left: 19%;
  width: 27%;
}

.makedonija_hover {
  margin-top: -26vh;
  margin-left: 64%;
  width: 31%;
}

.right {
  text-align: right;
}

.border {
  border-top: 2px solid #c30c0e;
  padding-top: 4px;
}

.red {
  color: #c30c0e;
}

@media only screen and (max-width: 650px) {
  .poliplan_in_numbers_img img {
    display: none;
  }

  .hover {
    position: absolute;
    font-weight: 600;
    letter-spacing: 4px;
    font-size: 10px;
  }

  .poliplan_in_numbers_mobile img {
    display: block;
    object-fit: fill;
    height: 99vh;
    width: 100%;
    z-index: -1;
  }

  .subotica {
    margin-top: -91.2vh;
    margin-left: 33.1%;
  }

  .subotica_okvir {
    margin-top: -91vh;
    margin-left: 33.1%;
  }

  .subotica_hover {
    margin-top: -91.2vh;
    margin-left: 33.1%;
    width: 50%;
  }

  .novi_sad {
    margin-top: -79.42vh;
    margin-left: 30.33%;
  }

  .novi_sad_okvir {
    margin-top: -79.42vh;
    margin-left: 30.33%;
  }

  .novi_sad_hover {
    margin-top: -79.42vh;
    margin-left: 35.33%;
    width: 50%;
  }

  .sabac {
    margin-top: -69.1vh;
    margin-left: 33.45%;
  }

  .sabac_okvir {
    margin-top: -69.1vh;
    margin-left: 33.45%;
  }

  .sabac_hover {
    margin-top: -70vh;
    margin-left: 37%;
    width: 50%;
  }

  .uzice {
    margin-top: -62.2vh;
    margin-left: 31.85%;
  }

  .uzice_okvir {
    margin-top: -62.2vh;
    margin-left: 31.85%;
  }

  .uzice_hover {
    margin-top: -63vh;
    margin-left: 35%;
    width: 50%;
  }

  .cacak {
    margin-top: -63.2vh;
    margin-left: 39.8%;
  }

  .cacak_okvir {
    margin-top: -63.2vh;
    margin-left: 39.8%;
  }

  .cacak_hover {
    margin-top: -64vh;
    margin-left: 43%;
    width: 50%;
  }

  .kragujevac {
    margin-top: -66.7vh;
    margin-left: 46.94%;
  }

  .kragujevac_okvir {
    margin-top: -66.7vh;
    margin-left: 46.94%;
  }

  .kragujevac_hover {
    margin-top: -68vh;
    margin-left: 50%;
    width: 50%;
  }

  .kraljevo {
    margin-top: -61.8vh;
    margin-left: 47.66%;
  }

  .kraljevo_okvir {
    margin-top: -61.8vh;
    margin-left: 47.66%;
  }

  .kraljevo_hover {
    margin-top: -63vh;
    margin-left: 49%;
    width: 50%;
  }

  .nis {
    margin-top: -49.5vh;
    margin-left: 62.5%;
  }

  .nis_okvir {
    margin-top: -49.5vh;
    margin-left: 62.62%;
  }

  .nis_hover {
    margin-top: -50vh;
    margin-left: 12%;
    width: 50%;
  }

  .crna_gora {
    margin-top: -35vh;
    margin-left: 14.65%;
  }

  .crna_gora_okvir {
    margin-top: -35vh;
    margin-left: 14.65%;
  }

  .crna_gora_hover {
    margin-top: -36vh;
    margin-left: 19%;
    width: 50%;
  }

  .makedonija {
    margin-top: -22.25vh;
    margin-left: 62.1%;
  }

  .makedonija_okvir {
    margin-top: -22.25vh;
    margin-left: 62.8%;
  }

  .makedonija_hover {
    margin-top: -23vh;
    margin-left: 13%;
    width: 50%;
  }

  .beograd {
    margin-top: -77vh;
    margin-left: 40%;
    width: 3vw;
    height: 4.5vh;
  }

  .beograd_hover {
    margin-top: -80vh;
    margin-left: 5%;
    width: 40%;
  }

  .beograd_naslov {
    border-bottom: 2px solid #c30c0e;
    padding-bottom: 4px;
    margin-bottom: 4px;
  }

  .beograd_right_hover {
    margin-top: -75vh;
    margin-left: 50%;
    width: 45%;
  }
}
