.poliplan-info {
  width: 100%;
  height: 90vh;
  display: flex;
}

.left-poliplan-info {
  display: block;
  width: 45%;
  margin-left: 10%;
  margin-top: 10vh;
  position: absolute;
}

.right-poliplan-info {
  width: 42%;
  margin-top: 10vh;
  margin-left: 53%;
}

.poliplan-info-naslov {
  font-size: 100px;
  letter-spacing: -11px;
  line-height: 72%;
  font-weight: 800;
}

#naslov-info {
  font-size: 80px;
  letter-spacing: -5px;
  font-weight: 500;
}

.poliplan-info-tekst {
  margin-top: 30px;
  font-size: 16px;
}

.poliplan-info-tekst-bold {
  font-weight: 900;
  letter-spacing: -2px;
  font-size: 18px;
  margin-top: 20px;
}

.poliplan-info-tekst-detalji {
  padding-top: 10px;
}

#poliplan-info-email {
  margin-top: 10px;
}

@media only screen and (max-width: 650px) {
  .page {
    display: none;
  }
  #poliplan-info-email {
    padding-bottom: 30px;
  }
}
