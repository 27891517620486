.body {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-height {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Slider {
  width: 100%;
  height: 75vh;
  border-radius: 10px;
  overflow: hidden;
}

.slides {
  width: 500%;
  height: 500px;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
}

.slide img {
  width: 100%;
  height: 75vh;
  object-fit: cover;
}

.Slide_firtsAboutUs .slide img {
  width: 100%;
  height: 75vh;
  object-fit: contain;
}

/*TUUU I KOD NAVIGATION-MANUAL MORA DA SE POKLOPI ISTI WIDTH KAO STO STAVIMO ZA SLAJDER */
.navigation-manual {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70vh;
}

.manual-btn {
  margin: 0;
  border: 1px solid white;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 30px;
}

.manual-btn:hover {
  background: white;
}

/* OVDE JE SLIDE */
#radio1:checked ~ .first {
  margin-left: 0;
}

#radio2:checked ~ .first {
  margin-left: -20%;
}

#radio3:checked ~ .first {
  margin-left: -40%;
}

#radio4:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE */

/* OVDE JE SLIDE1 */
#radio1_1:checked ~ .first {
  margin-left: 0;
}

#radio2_1:checked ~ .first {
  margin-left: -20%;
}

#radio3_1:checked ~ .first {
  margin-left: -40%;
}

#radio4_1:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE1 */

/* OVDE JE SLIDE2 */
#radio1_2:checked ~ .first {
  margin-left: 0;
}

#radio2_2:checked ~ .first {
  margin-left: -20%;
}

#radio3_2:checked ~ .first {
  margin-left: -40%;
}

#radio4_2:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE2 */

/* OVDE JE SLIDE3 */
#radio1_3:checked ~ .first {
  margin-left: 0;
}

#radio2_3:checked ~ .first {
  margin-left: -20%;
}

#radio3_3:checked ~ .first {
  margin-left: -40%;
}

#radio4_3:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE3 */

/* OVDE JE SLIDE4 */
#radio1_4:checked ~ .first {
  margin-left: 0;
}

#radio2_4:checked ~ .first {
  margin-left: -20%;
}

#radio3_4:checked ~ .first {
  margin-left: -40%;
}

#radio4_4:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE4 */

/* OVDE JE SLIDE5 */
#radio1_5:checked ~ .first {
  margin-left: 0;
}

#radio2_5:checked ~ .first {
  margin-left: -20%;
}

#radio3_5:checked ~ .first {
  margin-left: -40%;
}

#radio4_5:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE5 */

/* OVDE JE SLIDE6 */
#radio1_6:checked ~ .first {
  margin-left: 0;
}

#radio2_6:checked ~ .first {
  margin-left: -20%;
}

#radio3_6:checked ~ .first {
  margin-left: -40%;
}

#radio4_6:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE6 */

/* OVDE JE SLIDE7 */
#radio1_7:checked ~ .first {
  margin-left: 0;
}

#radio2_7:checked ~ .first {
  margin-left: -20%;
}

#radio3_7:checked ~ .first {
  margin-left: -40%;
}

#radio4_7:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE7 */

/* OVDE JE SLIDE8 */
#radio1_8:checked ~ .first {
  margin-left: 0;
}

#radio2_8:checked ~ .first {
  margin-left: -20%;
}

#radio3_8:checked ~ .first {
  margin-left: -40%;
}

#radio4_8:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE8 */

/* OVDE JE SLIDE9 */
#radio1_9:checked ~ .first {
  margin-left: 0;
}

#radio2_9:checked ~ .first {
  margin-left: -20%;
}

#radio3_9:checked ~ .first {
  margin-left: -40%;
}

#radio4_9:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE9 */

/* OVDE JE SLIDE10 */
#radio1_10:checked ~ .first {
  margin-left: 0;
}

#radio2_10:checked ~ .first {
  margin-left: -20%;
}

#radio3_10:checked ~ .first {
  margin-left: -40%;
}

#radio4_10:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE10 */

/* OVDE JE SLIDE11 */
#radio1_11:checked ~ .first {
  margin-left: 0;
}

#radio2_11:checked ~ .first {
  margin-left: -20%;
}

#radio3_11:checked ~ .first {
  margin-left: -40%;
}

#radio4_11:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE11 */

/* OVDE JE SLIDE12 */
#radio1_12:checked ~ .first {
  margin-left: 0;
}

#radio2_12:checked ~ .first {
  margin-left: -20%;
}

#radio3_12:checked ~ .first {
  margin-left: -40%;
}

#radio4_12:checked ~ .first {
  margin-left: -60%;
}
/* DOVDE JE SLIDE12 */

/*TUUU I KOD NAVIGATION-MANUAL MORA DA SE POKLOPI ISTI WIDTH KAO STO STAVIMO ZA SLAJDER */
.navigation-auto {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 70vh;
}

.navigation-auto div {
  border: 1px solid white;
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
}

.navigation-auto div:not(:last-child) {
  margin-right: 30px;
}

/* OVDE JE SLIDE */
#radio1:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE */

/* OVDE JE SLIDE1 */
#radio1_1:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_1:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_1:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_1:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE1 */

/* OVDE JE SLIDE2 */
#radio1_2:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_2:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_2:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_2:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE2 */

/* OVDE JE SLIDE3 */
#radio1_3:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_3:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_3:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_3:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE3 */

/* OVDE JE SLIDE4 */
#radio1_4:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_4:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_4:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_4:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE4 */

/* OVDE JE SLIDE5 */
#radio1_5:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_5:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_5:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_5:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE5 */

/* OVDE JE SLIDE6 */
#radio1_6:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_6:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_6:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_6:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE6 */

/* OVDE JE SLIDE7 */
#radio1_7:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_7:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_7:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_7:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE7 */

/* OVDE JE SLIDE8 */
#radio1_8:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_8:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_8:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_8:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE8 */

/* OVDE JE SLIDE9 */
#radio1_9:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_9:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_9:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_9:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE9 */

/* OVDE JE SLIDE10 */
#radio1_10:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_10:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_10:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_10:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE10 */

/* OVDE JE SLIDE11 */
#radio1_11:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_11:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_11:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_11:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLID11 */

/* OVDE JE SLIDE12 */
#radio1_12:checked ~ .navigation-auto .auto-btn1 {
  background: white;
}

#radio2_12:checked ~ .navigation-auto .auto-btn2 {
  background: white;
}

#radio3_12:checked ~ .navigation-auto .auto-btn3 {
  background: white;
}

#radio4_12:checked ~ .navigation-auto .auto-btn4 {
  background: white;
}
/* DOVDE JE SLIDE12 */

.arrow {
  position: absolute;
  margin-top: 33vh;
  width: 100%;
  z-index: 500;
}

.arrow button {
  background: none;
  border: none;
  font-size: 40px;
  color: white;
}

.arrow .next {
  float: right;
  cursor: pointer;
  margin-right: 2%;
}

.arrow .prew {
  margin-left: 2%;
  cursor: pointer;
}

@media only screen and (max-width: 650px) {
  .slide img {
    height: 300px;
    object-fit: cover;
  }

  .page-right-mobile-partneri .slide img {
    height: 300px;
    object-fit: contain;
  }
}
