.middlePage {
  display: flex;
}

#mobile-middle-page {
  display: none;
}

.titleLeft {
  margin-left: 150px;
  animation: showFromLeft 1s linear 0s alternate;
}

.titlesAboutUs {
  position: absolute;
  font-size: 10vh;
  text-align: left;
  letter-spacing: -6.8px;
  line-height: 90%;
  margin-top: 15vh;
  z-index: 100;
}

.bold {
  font-weight: 900;
  font-size: 14vh;
  letter-spacing: -6.8px;
}

.about-us-text {
  position: absolute;
  color: #a4a4a4;
  font-weight: 900;
  margin: 42vh 0 0 1%;
  z-index: 100;
}

.about-us-text-hover {
  margin-left: 2%;
  line-height: 100%;
  width: 30%;
  padding: 10px 0;
  font-weight: normal;
  font-size: 2vh;
  letter-spacing: 0px;
  color: black;
  cursor: auto;
  z-index: 100;
}

.services-text-hover {
  margin-left: 2%;
  line-height: 100%;
  width: 35%;
  padding: 10px 0;
  font-weight: bold;
  font-size: 2vh;
  letter-spacing: 0px;
  color: black;
  cursor: auto;
  z-index: 100;
}

.hover-element {
  cursor: pointer;
  font-size: 6vh;
  letter-spacing: -3px;
}

.hover-element .caret-right {
  font-size: 6vh;
}

.about-us-text .hover-element:hover {
  color: black;
}

.about-us-text .hover-element:hover #iskustvo {
  display: block;
}

.about-us-text .hover-element:hover #caret-right-iskustvo {
  display: inline;
}

.about-us-text .hover-element:hover #nasa-misija {
  display: block;
}

.about-us-text .hover-element:hover #caret-right-nasa-misija {
  display: inline;
}

.about-us-text .hover-element:hover #vizija {
  display: block;
}

.about-us-text .hover-element:hover #caret-right-vizija {
  display: inline;
}

.about-us-text .hover-element:hover #caret-right-case-study {
  display: inline;
}

.about-us-text .hover-element:hover #caret-right-nas-dan {
  display: inline;
}

.caret-right {
  padding-right: 20px;
}

#iskustvo {
  display: none;
}

#nasa-misija {
  display: none;
}

#vizija {
  display: none;
}

#caret-right-iskustvo {
  display: none;
}

#caret-right-nasa-misija {
  display: none;
}

#caret-right-vizija {
  display: none;
}

#caret-right-case-study {
  display: none;
}

#caret-right-nas-dan {
  display: none;
}

.imgMigglePage {
  display: none;
}

@keyframes showFromLeft {
  0% {
    margin-left: -400px;
  }
  100% {
    margin-left: 150px;
  }
}

@keyframes showFromRight {
  0% {
    right: -800px;
  }
  100% {
    right: 5%;
  }
}

@media (max-width: 1200px) {
  .titlesAboutUs {
    font-size: 10vh;
  }

  .bold {
    font-size: 15vh;
  }

  .about-us-text {
    margin: 40vh 0 0 2%;
  }

  .hover-element {
    font-size: 5vh;
  }

  .hover-element .caret-right {
    font-size: 5vh;
  }
}

/* @media (max-width: 1100px){
    .titlesAboutUs {
        margin-left: -40px;
    }

    .about-us-text {
        margin: 40vh 0 0 -30px;
    }
} */

@media (max-width: 1000px) {
  .titlesAboutUs {
    font-size: 8vh;
  }

  .bold {
    font-size: 12vh;
  }

  .hover-element {
    font-size: 4.5vh;
  }

  .hover-element .caret-right {
    font-size: 4.5vh;
  }
}

@media (max-width: 950px) {
  .titlesAboutUs {
    margin-left: -40px;
  }

  .about-us-text {
    margin: 40vh 0 0 -20px;
  }

  .hover-element {
    font-size: 4vh;
  }

  .hover-element .caret-right {
    font-size: 4vh;
  }

  .about-us-text-hover {
    width: 35%;
  }

  .services-text-hover {
    width: 45%;
  }
}

@media (max-width: 650px) {
  .middlePage {
    display: none;
  }

  #mobile-middle-page {
    display: block;
    padding-bottom: 50px;
  }

  .about-us-text .hover-element:hover #iskustvo {
    display: none;
  }

  .about-us-text .hover-element:hover #caret-right-iskustvo {
    display: none;
  }

  .about-us-text .hover-element:hover #nasa-misija {
    display: none;
  }

  .about-us-text .hover-element:hover #caret-right-nasa-misija {
    display: none;
  }

  .about-us-text .hover-element:hover #vizija {
    display: none;
  }

  .about-us-text .hover-element:hover #caret-right-vizija {
    display: none;
  }

  .about-us-text .hover-element:hover #caret-right-case-study {
    display: none;
  }

  .about-us-text .hover-element:hover #caret-right-nas-dan {
    display: none;
  }
  .about-us-text {
    margin: 0 0 0 0;
  }
  .titleLeft {
    margin-left: 5%;
    margin-top: -3vh;

    animation: none;
  }
  .hover-element {
    font-size: 30px;
  }
}
