.slideServices .react-slideshow-container + ul.indicators {
  margin-top: -30px;
  margin-bottom: 25px;
}

.slideServices .react-slideshow-container + ul.indicators li {
  width: 15px;
}

.slideServices .react-slideshow-container .default-nav {
  background: transparent;
  display: block;
}

.whiteDots
  .react-slideshow-container
  + ul.indicators
  .each-slideshow-indicator::before {
  border: 3px solid white;
  background: white;
}

.blackDots
  .react-slideshow-container
  + ul.indicators
  .each-slideshow-indicator::before {
  border: 3px solid black;
  background: black;
}

.noneDots
  .react-slideshow-container
  + ul.indicators
  .each-slideshow-indicator::before {
  display: none;
}

.whiteArrow path {
  color: white;
}

.blackArrow path {
  color: black;
}

.slide-arrow {
  background: transparent;
  border: none;
  color: white;
  z-index: 10;
}

.slide-next {
  margin-left: -30px;
}

.slide-prew {
  margin-right: -30px;
}
