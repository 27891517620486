.screen1 {
  display: flex;
}

.textScreen1 {
  position: absolute;
  display: block;
  margin-top: 30vh;
  right: 3%;
  line-height: 80%;
  text-align: right;
  width: 100%;
  animation: showText 1s linear 0s alternate;
  animation-fill-mode: forwards;
}

.notDisplay {
  display: none;
}

.textScreen1 .hover-element a {
  position: relative;
  font-size: 120px;
  letter-spacing: -12px;
  color: #a4a4a4;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 72%;
}

.textONama {
  position: absolute;
  right: 3%;
  text-align: right;
  margin-top: 2vh;
  font-size: 37px;
  display: flex;
}

.hr-line {
  position: absolute;
  width: 52%;
  display: flex;
  height: 5vh;
  border-bottom: 2px solid #c30c0e;
  border-left: 2px solid #c30c0e;
  line-height: 0.1em;
  margin-left: 30%;
  margin-top: 0.5vh;
}

.screen1-text-hover {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  width: 30%;
  letter-spacing: 0px;
  line-height: 100%;
  margin-left: 65%;
  text-align: left;
  color: black;
  cursor: auto;
  z-index: 100;
}

#nasa-kuca {
  margin-left: 70%;
  width: 30%;
}

.textScreen1 .hover-element:hover #nasa-kuca {
  display: block;
}

.textScreen1 .hover-element:hover #nasa-porodica {
  display: block;
}

.textScreen1 .hover-element:hover #nasi-prijatelji {
  display: block;
}

.textScreen1 .hover-element:hover a {
  color: black;
  font-size: 130px;
}

.caret-right {
  font-size: 120px;
}

#nasa-kuca {
  display: none;
}

#nasa-porodica {
  display: none;
}

#nasi-prijatelji {
  display: none;
}

#caret-right-nasa-kuca {
  display: none;
}

#caret-right-nasa-porodica {
  display: none;
}

#caret-right-nasi-prijatelji {
  display: none;
}

.textScreen1 .hover-element:hover #caret-right-nasa-kuca {
  display: inline;
  font-size: 120px;
}

.textScreen1 .hover-element:hover #caret-right-nasa-porodica {
  display: inline;
  font-size: 120px;
}

.textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
  display: inline;
  font-size: 120px;
}

.button-screen1 {
  position: relative;
  padding-bottom: 5px;
  text-align: right;
}

.button-vise {
  background-color: #c30c0e;
  color: white;
  font-size: 16px;
  padding: 10px 30px 10px 30px;
  border: #c30c0e;
  border-radius: 25px;
  justify-content: right;
  cursor: pointer;
}

.caret-right-button {
  font-size: 16px;
  padding-left: 10px;
}

@media only screen and (max-width: 1500px) {
  .textScreen1 .hover-element a {
    font-size: 95px;
    line-height: 80%;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 105px;
  }
  .caret-right {
    font-size: 95px;
  }
  .hr-line {
    margin-left: 25%;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 95px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 95px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 95px;
  }
}

@media only screen and (max-width: 1150px) {
  .textScreen1 .hover-element a {
    font-size: 95px;
    line-height: 80%;
    letter-spacing: -10px;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 85px;
  }
  .caret-right {
    font-size: 80px;
  }
  .screen1-text-hover {
    width: 45%;
    margin-left: 50%;
  }
  .hr-line {
    margin-left: 20%;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 80px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 80px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 80px;
  }
}

@media only screen and (max-width: 850px) {
  .textScreen1 .hover-element a {
    font-size: 70px;
    line-height: 80%;
    letter-spacing: -7px;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 75px;
  }
  .caret-right {
    font-size: 70px;
  }
  .textONama {
    font-size: 30px;
    margin-top: 1vh;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 70px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 70px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .textScreen1 .hover-element a {
    font-size: 60px;
    line-height: 80%;
    letter-spacing: -7px;
  }

  .textONama {
    font-size: 27px;
  }

  .textScreen1 .hover-element:hover a {
    font-size: 65px;
  }
  .caret-right {
    font-size: 60px;
  }
  .hr-line {
    display: none;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 60px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 60px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 60px;
  }
}

@media only screen and (max-width: 680px) {
  .textScreen1 .hover-element:hover #nasa-kuca {
    display: none;
  }

  .textScreen1 .hover-element:hover #nasa-porodica {
    display: none;
  }

  .textScreen1 .hover-element:hover #nasi-prijatelji {
    display: none;
  }
  .textScreen1 .hover-element a {
    font-size: 50px;
    line-height: 80%;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 55px;
  }
  .caret-right {
    font-size: 50px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 50px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 50px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 50px;
  }
  .textONama {
    font-size: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .textScreen1 {
    margin-top: 50vh;
  }
  .textScreen1 .hover-element a {
    font-size: 11.5vw;
    color: black;
    right: 5%;
  }
  .screen1-text-hover {
    display: none;
  }
  .textScreen1 {
    animation: none;
  }
  .textONama {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .textScreen1 .hover-element a {
    font-size: 11.8vw;
    letter-spacing: -6px;
  }
}

@media only screen and (max-width: 400px) {
  .textScreen1 .hover-element a {
    letter-spacing: -5.5px;
  }
}

@media only screen and (max-height: 450px) {
  .textScreen1 .hover-element a {
    letter-spacing: -2px;
    font-size: 40px;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 45px;
  }
  .caret-right {
    font-size: 40px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 40px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 40px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 40px;
  }
  .screen1-text-hover {
    font-size: 12px;
    width: 50%;
    margin-left: 45%;
  }
}

@media only screen and (max-height: 350px) {
  .textScreen1 .hover-element a {
    font-size: 30px;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 35px;
  }
  .caret-right {
    font-size: 20px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-kuca {
    font-size: 20px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasa-porodica {
    font-size: 20px;
  }
  .textScreen1 .hover-element:hover #caret-right-nasi-prijatelji {
    font-size: 20px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1150px) {
  .textScreen1 .hover-element a {
    font-size: 105px;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 95px;
  }
}

@media only screen and (max-height: 600px) and (min-width: 1150px) {
  .textScreen1 .hover-element a {
    font-size: 70px;
  }
  .textScreen1 .hover-element:hover a {
    font-size: 80px;
  }
}
