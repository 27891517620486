.screen2 {
  display: flex;
}
.textScreen2 {
  position: absolute;
  display: block;
  margin-top: 30vh;
  right: 2%;
  font-size: 3vw;
  text-align: right;
  font-weight: 900;
  animation: showText 1s linear 0s alternate;
  animation-fill-mode: forwards;
  letter-spacing: -5px;
  line-height: 95%;
  width: 90%;
}

.title-screen2 {
  position: absolute;
  margin-left: 16%;
  color: #c30c0e;
  margin-top: 9vh;
  font-size: 60px;
  z-index: 200;
  line-height: 0.8;
  animation: show-title-screen2 3s linear 0s alternate;
}

.bold-text {
  font-size: 105px;
  font-weight: 900;
  letter-spacing: -10px;
}

@keyframes show-title-screen2 {
  0% {
    display: none;
    z-index: -10;
    margin-left: -1600px;
  }
  70% {
    display: none;
    z-index: -10;
    margin-left: -800px;
  }
  90% {
    display: block;
    z-index: 200;
    margin-left: 14%;
  }
  100% {
    display: block;
    z-index: 200;
    margin-left: 16%;
  }
}

.textScreen2 a {
  color: #a4a4a4;
  text-decoration: none;
}

.textScreen2 .strategija:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-strategija {
  display: none;
}

.textScreen2 .strategija:hover #caret-right-strategija {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .kreativni-koncepti:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-kreativni-koncepti {
  display: none;
}

.textScreen2 .kreativni-koncepti:hover #caret-right-kreativni-koncepti {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .event-managment:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-event-managment {
  display: none;
}

.textScreen2 .event-managment:hover #caret-right-event-managment {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .graficki-dizajn:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-graficki-dizajn {
  display: none;
}

.textScreen2 .graficki-dizajn:hover #caret-right-graficki-dizajn {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .proizvodnja-pos-pop:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-proizvodnja-pos-pop {
  display: none;
}

.textScreen2 .proizvodnja-pos-pop:hover #caret-right-proizvodnja-pos-pop {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .impresivne-postavke:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-impresivne-postavke {
  display: none;
}

.textScreen2 .impresivne-postavke:hover #caret-right-impresivne-postavke {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .stuff-leasing:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-stuff-leasing {
  display: none;
}

.textScreen2 .stuff-leasing:hover #caret-right-stuff-leasing {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .video-produkcija:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-video-produkcija {
  display: none;
}

.textScreen2 .video-produkcija:hover #caret-right-video-produkcija {
  display: inline;
  font-size: 4vw;
}

.textScreen2 .trade-horeca:hover a {
  color: black;
  font-size: 4vw;
}

#caret-right-trade-horeca {
  display: none;
}

.textScreen2 .trade-horeca:hover #caret-right-trade-horeca {
  display: inline;
  font-size: 4vw;
}

@media only screen and (max-width: 1500px) {
  .title-screen2 {
    font-size: 55px;
  }
  .bold-text {
    font-size: 95px;
  }

  .textScreen2 {
    letter-spacing: -4px;
  }
}

@media only screen and (max-width: 1150px) {
  .title-screen2 {
    font-size: 45px;
  }
  .bold-text {
    font-size: 75px;
    letter-spacing: -8px;
  }
}

@media only screen and (max-width: 850px) {
  .title-screen2 {
    font-size: 35px;
  }
  .bold-text {
    font-size: 65px;
    letter-spacing: -8px;
  }
  .textScreen2 {
    letter-spacing: -1px;
  }
}

@media only screen and (max-width: 750px) {
  .title-screen2 {
    font-size: 28px;
  }
  .bold-text {
    font-size: 56px;
    letter-spacing: -5px;
  }
}

@media only screen and (max-width: 680px) {
  .title-screen2 {
    font-size: 20px;
  }
  .bold-text {
    font-size: 45px;
    letter-spacing: -5px;
  }
}

@media only screen and (max-width: 650px) {
  .screen2 {
    display: none;
  }
  .textScreen2 {
    margin-top: 10px;
    margin-left: 5%;
    text-align: left;
    position: relative;
    font-size: 20px;
  }
}
