.clanovi_timovi {
  width: 85%;
  margin-left: 15%;
  display: flex;
}
#prvi_red .clan_t {
  margin-top: 5%;
}

.clanovi_timovi .clan_t {
  width: 23%;
  text-align: center;
  margin-left: 0%;
  font-size: 0px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.clanovi_timovi img {
  width: 100%;
  margin: 0 0 0px 0;
  cursor: pointer;
}

.clanovi_timovi img:hover {
  transform: scale(1.5);
  transition: 0.5s;
}

.tl_clan {
  color: #71cbc9;
  font-size: 17px;
  margin-bottom: -10px;
  /* margin-top: -10px; */
}

.container {
  position: relative;
  overflow: hidden; /* hide the overflow */
  vertical-align: middle;
  text-align: right;
  color: white;
}

.clanovi_timovi #logo img:hover {
  transform: none;
  transition: none;
}

#logo img {
  padding-top: 40%;
  padding-left: 6px;
  width: 95%;
}

.position {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: none;
}

@media (max-width: 850px) {
  .clanovi_timovi .clan_t {
    width: 21%;
    margin-left: 3.2%;
  }
  .naslov_timovi div1 {
    display: none;
  }
}

@media (max-width: 700px) {
  .clanovi_timovi {
    margin: 70px 0 0 0;
  }

  .clanovi_timovi .clan_t {
    width: 24%;
    margin-left: 0.8%;
  }
}

@media (max-width: 500px) {
  .clanovi_timovi {
    display: block;
    width: 70%;
    margin: 10px 0 0 15%;
  }

  .clanovi_timovi .clan_t {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
