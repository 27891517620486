.headerMobile {
  display: none;
}

@media only screen and (max-width: 650px) {
  .headerMobile {
    display: block;
  }

  .lineMobile {
    position: fixed;
    display: flex;
    justify-content: space-between;
    background-color: #c30c0e;
    color: white;
    height: 10vh;
    bottom: 0;
    min-width: 100%;
    z-index: 1000;
  }

  .lineMobile-links {
    display: flex;
    margin-right: 20px;
    height: 10vh;
  }

  .lineMobile-links a img {
    height: 5vh;
    margin-top: 2.5vh;
    padding-left: 15px;
  }

  .logo-footer {
    height: 8vh;
    margin-top: 1vh;
    margin-left: 10px;
  }

  .fa_bars_Mobile {
    position: fixed;
    z-index: 1000;
  }

  .fa_bars_Mobile .header_list_Mobile {
    display: none;
  }

  .fa_bars_Mobile input {
    display: none;
  }

  .fa_bars_Mobile .fa_bars_icon {
    color: white;
    font-size: 35px;
    padding: 8px;
    cursor: pointer;
    background-color: #c30c0e;
    margin-top: 4vh;
    margin-left: 5vw;
  }

  .header_list_Mobile ul {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width: 30vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-top: 0;
    margin-left: 5vw;
  }

  .header_list_Mobile li {
    text-align: left;
    list-style: none;
    color: black;
    font-size: 6vw;
    margin-left: -5vw;
  }

  .header_list_Mobile li a {
    text-decoration: none;
    color: black;
  }
}
