.services {
    width: 100%;
    margin: 0;
    padding: 0;
}

.logoServices {
    position: fixed;
    width: 150px;
    margin-left: 105px;
    margin-top: 2vh;
}

.firtsService {
    display: flex;
}

.MP_firtsService {
    width: 55%;
}

.Slide_firtsService {
    position: relative;
    width: 45%;
    float: right;
    margin-right: 2%;
    animation: showFromRight 1.5s linear 0s alternate;
}

@keyframes showFromRight {
    0%   { right: -800px; }
    100% { right: 0%; margin-right: 2%;}
}

@media only screen and (max-width: 650px) { 
    .MP_firtsService {
        width: 100%;
    }

    .logoServices {
        position: absolute;
        height: 5vh;
        width: auto;
        margin: 2vh 7% 0 0;
        right: 0;
    }

    .Slide_firtsService {
        display: none;
    }
}