/* .philips-page {
  background: url("../assets/philips.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  min-width: 100%;
  min-height: 100vh;
} */

.philips-div {
  right: 0%;
  width: 30%;
  height: 250px;
  position: absolute;
  padding-left: 0;
  padding-top: 5%;
  padding-right: 2%;
  background-color: rgba(99, 59, 129, 0.4);
  text-align: right;
}

.philips-pdf {
  color: rgba(255, 255, 255, 1);
  font-size: 30px;
  margin-top: 10px;
  text-align: right;
  font-weight: bold;
  text-decoration: none;
}

.philips-div p {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  margin-top: 10px;
  text-align: right;
  font-weight: bold;
  text-decoration: none;
}

.philips-img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
  position: absolute;
}

.philips-div-mobile {
  display: none;
}

.philips-img-mobile {
  display: none;
}

@media only screen and (max-width: 700px) {
  .philips-pdf {
    font-size: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .philips-img {
    display: none;
  }

  .philips-div {
    display: none;
  }

  .philips-div-mobile {
    display: block;
    margin-top: 73%;
    width: 100%;
    position: absolute;
    background-color: rgba(99, 59, 129, 0.4);
    text-align: center;
    padding-top: 2%;
    padding-bottom: 5%;
  }

  .philips-pdf-mobile {
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
  }

  .philips-div-mobile p {
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
  }

  .philips-img-mobile {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: fill;
    position: absolute;
  }
}

@media only screen and (max-width: 420px) and (min-height: 800px) {
  .philips-div-mobile {
    margin-top: 100%;
  }
}

@media only screen and (max-width: 420px) and (min-height: 740px) and (max-height: 799px) {
  .philips-div-mobile {
    margin-top: 90%;
  }
}

@media only screen and (max-width: 330px) {
  .philips-pdf-mobile {
    font-size: 20px;
  }
}
