#page-case-study .page-img {
  height: 99.5vh;
}

.case-study-mobile {
  display: none;
}

.page-slide-study {
  position: relative;
  width: 100%;
  margin-right: 0%;
}
#page-case-study .blackArrow path {
  display: none;
}

.text-in-case-study {
  position: absolute;
  z-index: 10;
  width: 80%;
  margin-left: 150px;
  color: white;
  margin-top: 10vh;
  display: flex;
}

.page-title-case-study {
  color: white;
  font-size: 100px;
  letter-spacing: -11px;
  line-height: 72%;
  font-weight: 800;
}

.row-case-study {
  display: block;
  width: 40%;
}

.page-text-case-study {
  font-size: 25px;
  font-weight: 600;
}

.img-case-study {
  width: 100%;
  display: flex;
}
.img-case-study #evroliga {
  justify-content: center;
  width: 200px;
  margin-left: 10%;
}

.one-image-case-study {
  width: 40%;
}

.img-case-study #days-logo {
  margin-left: 0%;
  width: 100px;
}

.img-case-study #evroliga-logo {
  width: 100px;
  margin-left: 0px;
}

@media only screen and (max-width: 650px) {
  .text-in-case-study {
    display: none;
  }
  .page-slide-study {
    display: none;
  }

  .case-study-mobile {
    display: block;
  }
}
