.homepage {
  display: flex;
}

html {
  scroll-behavior: smooth;
}

.imgHomepage {
  position: relative;
  height: 84vh;
  width: 100%;
  margin-left: 100x;
  margin-top: 8vh;
  object-fit: cover;
  animation: showImage 1s linear 0s alternate;
}
.firstAnimation {
  width: 50%;
}
.nothing {
  height: 8vh;
}

.img1to1 {
  position: absolute;
  width: 30px;
  margin-top: 10vh;
  left: 27vw;
  animation: show-logo-1to1 1.5s linear 0s alternate;
}

#turn {
  color: #c30c0e;
}

.textHomepage {
  position: absolute;
  margin-top: 28vh;
  right: 3%;
  font-size: 210px;
  letter-spacing: -18px;
  line-height: 72%;
  text-align: right;
  font-weight: 800;
  padding-left: 10px;
  animation: showText 1s linear 0s alternate;
  animation-fill-mode: forwards;
}

.titleHomepage {
  position: absolute;
  margin-left: 31%;
  margin-top: 9vh;
  line-height: 85%;
  font-size: 50px;
  color: #c30c0e;
  animation: show-title-homepage 2s linear 0s alternate;
}

.boldText {
  font-weight: 900;
}

.noBoldText {
  font-weight: 300;
}

@keyframes showText {
  0% {
    right: -200px;
  }
  100% {
    right: 2%;
  }
}

@keyframes show-title-homepage {
  0% {
    z-index: -10;
    opacity: 0;
    margin-left: -50%;
  }
  70% {
    z-index: -10;
    opacity: 0;
    margin-left: -50%;
  }
  90% {
    display: block;
    z-index: 1;
    margin-left: 28%;
  }
  100% {
    display: block;
    z-index: 1;
    margin-left: 31%;
  }
}

@keyframes show-logo-1to1 {
  0% {
    z-index: -10;
    opacity: 0;
    margin-left: -50%;
  }
  70% {
    z-index: -10;
    opacity: 0;
    margin-left: -50%;
  }
  90% {
    display: block;
    z-index: 1;
    left: 25vw;
  }
  100% {
    display: block;
    z-index: 1;
    left: 27vw;
  }
}

.logo {
  position: absolute;
  margin-top: 8vh;
  width: 300px;
  right: 3%;
  z-index: 100;
  animation: showLogo 3s linear 0s alternate;
}

.logoScroll {
  position: fixed;
  width: 130px;
  margin-left: 10px;
  margin-top: 2vh;
  z-index: 1000;
}

.logo-on-page {
  width: 130px;
  margin-top: 2vh;
  z-index: 20;
  margin-left: 1%;
  position: absolute;
}

.textHomepage-responsive {
  display: none;
}

@keyframes showLogo {
  0% {
    z-index: -10;
    opacity: 0;
    right: -50%;
  }
  60% {
    z-index: -10;
    opacity: 0;
    right: -50%;
  }
  70% {
    display: block;
    z-index: 1;
    right: -100px;
  }
  100% {
    display: block;
    z-index: 1;
    right: 2%;
  }
}

@media only screen and (max-width: 1500px) {
  .textHomepage {
    font-size: 150px;
  }
  .titleHomepage {
    font-size: 40px;
  }
  .logo {
    width: 200px;
  }
}

@media only screen and (max-width: 1150px) {
  .textHomepage {
    font-size: 120px;
    letter-spacing: -12px;
  }
  .titleHomepage {
    font-size: 35px;
  }
  .logo {
    width: 150px;
  }
  .logoScroll {
    width: 120px;
    margin-left: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .textHomepage {
    font-size: 90px;
    letter-spacing: -5px;
    line-height: 82%;
  }
  .titleHomepage {
    font-size: 28px;
  }
  .logoScroll {
    margin-top: 1vh;
    width: 90px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .textHomepage {
    font-size: 70px;
    letter-spacing: 0px;
    line-height: 92%;
  }

  .titleHomepage {
    font-size: 23px;
  }
  .logo {
    width: 100px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1150px) {
  .textHomepage {
    font-size: 150px;
  }
}

/* @media only screen and (max-height: 600px) and (min-width: 1150px) { 
    .textHomepage {
        font-size: 120px;
        letter-spacing: -12px;
    }

    .titleHomepage {
        font-size: 40px;
    }
} */

@media only screen and (max-width: 650px) {
  .firstAnimation {
    width: 100%;
  }

  .logoScroll {
    display: none;
  }

  .nothing {
    height: 0vh;
  }

  .titleHomepage,
  .img1to1 {
    display: none;
  }

  .imgHomepage {
    width: 92%;
    height: 75vh;
    margin-bottom: 8vh;
  }

  .logo {
    margin-right: 5%;
    margin-top: 2vh;
    height: 5vh;
  }

  .textHomepage {
    margin-right: 5%;
    margin-top: 40vh;
    font-size: 12vw;
  }

  .textHomepage-noResponsive {
    display: none;
  }

  .textHomepage-responsive {
    display: block;
    font-size: 17vw;
    margin-top: 25vh;
  }

  .logo {
    margin-top: 3.5vh;
    width: 43vw;
    height: auto;
    animation: none;
  }
}
