.poliplan-prijavi-se {
  width: 100%;
  height: 100vh;
}

.img-poliplan-prijavi-se {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
}

.text-prijavi-se {
  position: absolute;
  margin-left: 15%;
}

.prijavi-se-naslov {
  margin-top: 13vh;
  font-size: 100px;
  letter-spacing: -11px;
  line-height: 72%;
  font-weight: 800;
}

#prijavi-se {
  font-size: 80px;
  letter-spacing: -5px;
  font-weight: 500;
}

#kontakt {
  font-size: 80px;
  letter-spacing: -5px;
  font-weight: 500;
}

#poliplan-1to1 {
  color: #c30c0e;
  letter-spacing: -2px;
}

.prijavi-se-formular {
  margin-top: 40px;
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 900;
  width: 100%;
  display: flex;
}

#prijavi-se-text-file {
  font-size: 16px;
  font-weight: normal;
}

.row-prijavi-se {
  width: 50%;
  display: block;
}

.form-group {
  margin-top: 20px;
}

.prijavi-se-form {
  border-color: black;
  background: none;
  border-right: none;
  border-top: none;
  border-left: none;
}

input:focus {
  outline: none;
}

.prijavi-se-button {
  background-color: #c30c0e;
  color: white;
  font-size: 13px;
  padding: 10px 30px 10px 30px;
  border: #c30c0e;
  border-radius: 25px;
  justify-content: right;
  cursor: pointer;
}

@media only screen and (max-width: 650px) {
  .poliplan-prijavi-se {
    display: none;
  }
  .row-prijavi-se {
    width: 100%;
  }
}
